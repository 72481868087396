import {CDateRangePicker} from "@coreui/react-pro";
import {LanguageUtils} from "../../../utils/LanguageUtils";
import {CButton} from "@coreui/react";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import "./BbxDoubleDatePicker.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

interface BbxDoubleDatePickerProps {
    values: { dateStart: Date | null, dateEnd: Date | null };
    onChange: (dateStart: Date | null, dateEnd: Date | null) => void;
}

export function BbxDoubleDatePicker({
                                        values,
                                        onChange
                                    }: BbxDoubleDatePickerProps) {
    const {t} = useTranslation();
    const [startDate, setStartDate] = React.useState<Date | null>(values.dateStart);
    const [endDate, setEndDate] = React.useState<Date | null>(values.dateEnd);
    useEffect(() => {

        setStartDate(values.dateStart);
    }, [values.dateStart]);

    useEffect(() => {
        setEndDate(values.dateEnd);
    }, [values.dateEnd]);


    const customRanges = {
        [t('This month')]: [
            new Date(new Date().setDate(1)),
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        ],
        [t('Last month')]: [
            new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
            new Date(new Date().getFullYear(), new Date().getMonth(), 0),
        ],
        [t('This year')]: [
            new Date(new Date().getFullYear(), 0, 1),
            new Date(new Date().getFullYear(), 11, 31),
        ],
        [t('Last year')]: [
            new Date(new Date().getFullYear() - 1, 0, 1),
            new Date(new Date().getFullYear() - 1, 11, 31),
        ],
    };

    return (
        <div className={"input-group"}>
            <CDateRangePicker
                style={{width:"87%"}}
                placeholder={[t("StartDate"), t("EndDate")]}
                id="leadDateInput"
                locale={LanguageUtils.getLocale(true)}
                startDate={startDate}
                endDate={endDate}
                ranges={customRanges}
                rangesButtonsVariant={"outline"}
                rangesButtonsColor={"secondary"}

                onStartDateChange={(date) => {
                    setStartDate(date)
                    onChange(date, endDate);
                }}
                onEndDateChange={(date) => {
                    setEndDate(date);
                    onChange(startDate, date);

                }}
                cleaner={false}
            />
            {
                <CButton
                    style={{borderRadius: "0px", border: "2px solid var(--border-blue)", boxShadow: "none",width:"60px"}}
                    className="btn btn-secondary"
                    onClick={() => {
                        onChange(null, null);
                    }}
                >
                    <FontAwesomeIcon icon={faXmark}/>
                </CButton>

            }
        </div>
    )
}