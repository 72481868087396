import React, {useEffect, useState} from "react";
import {CButton, CForm, CFormInput} from "@coreui/react";
import {InvoiceAcctDTO} from "../../../Data/InvoiceAcctDTO";
import {MemberView} from "../../../Data/view/MemberModel/MemberView";
import {GeneralUtils} from "../../../utils/GeneralUtils";
import {VatUtil} from "../../../utils/VatUtil";
import {LanguageUtils} from "../../../utils/LanguageUtils";
import {OgoneService} from "../../../Services/Supplier/OgoneService";
import {OgonePropertiesDTO} from "../../../Data/OgonePropertiesDTO";
import {RouteUtils} from "../../../utils/RouteUtils";

interface OgoneModalProps {


    t: (key: string) => string;
    invoice: InvoiceAcctDTO;
    user: MemberView;
    ogoneProperties: OgonePropertiesDTO;
    type: string;
}

const OgoneForm: React.FC<OgoneModalProps> = ({
                                                   t,
                                                   invoice,
                                                   user,
                                                   ogoneProperties,
                                                   type
                                               }) => {

    const [sha1Key, setSha1Key] = useState<string>("");

    useEffect(() => {


        lsKeyGenerate();
    }, []);

    const lsKeyGenerate = () => {
        let lsKey = "";

        lsKey = lsKey + ogoneProperties.context + type + invoice.id;

        if (invoice.vatExempted) {
            lsKey = lsKey + (invoice.amount * 100) + "EUR" + ogoneProperties.pspid + ogoneProperties.key;
        } else {
            lsKey = lsKey + ((VatUtil.VATCalculate(invoice.amount)) * 100) + "EUR" + ogoneProperties.pspid + ogoneProperties.key;
        }

        OgoneService.getGeneratedSHA1Key(lsKey).then((response) => {
            lsKey = response;
            setSha1Key(lsKey);
        });

        return lsKey;
    }

    const getAmountCalculated = (invoice: InvoiceAcctDTO) => {
        if (invoice.vatExempted) {
            return (invoice.amount * 100);
        } else {
            return ((VatUtil.VATCalculate(invoice.amount)) * 100);
        }
    }

    const getTitleByContext = () => {

        if (ogoneProperties.context === "be") {
            return "Bobex.be";
        } else {
            return "Bobex.nl";
        }
    }

    const getServerLink = () => {

        const serverName: string = window.location.hostname;
        const context: string = getTitleByContext();
        let urlSpring: string;

        if (serverName === "localhost") {
            urlSpring = "http://localhost:3000"
        } else if (serverName === "web009.bobex.com") {
            urlSpring = "https://web009.bobex.com"
        } else {
            urlSpring = "https://www." + context.toLowerCase();
        }

        return urlSpring;
    }

    const locationUrl = () => {
        let urlReact: string = "";

        if (window.location.hostname.includes('localhost') || window.location.hostname.includes('web009')) {
            if (RouteUtils.getContext() === "be") {
                urlReact = '/extranet-be';
            } else if (RouteUtils.getContext() === 'nl') {
                urlReact = '/extranet-nl';
            } else if (RouteUtils.getContext() === 'ci') {
                urlReact = '/extranet-ci';
            } else if (RouteUtils.getContext() === 'vbk') {
                urlReact = '/extranet-vbk';
            } else {
                urlReact = '/extranet-be';
            }
        }

        return urlReact;
    }

    // TODO bobex.nl => bobex.nl/bobexnl
    const getHomeUrl = () => {

        const link: string = getServerLink();
        const location: string = locationUrl();

        if (type === "m") {
            return link + location + "/supplier/account/invoices";
        }
        // TODO: implement when ready
        /*else {
            return "https://"+ context +"/control/ogone_return?user.id=" + user.id + "&invoiceacct.id=" + invoicePackId;
        }*/
    }

    const getParamPlus = () => {

        if (type === "m") {
            return "country=" + ogoneProperties.context + "&invoiceacct.id=" + invoice.id + "&type=" + type;
        }
        // TODO: implement when ready
        /*else {
            return "country=" + ogoneProperties.context + "&invoicepack.id=" + invoicePackId + "&type=" + type;
        }*/
    }

    return (
        <>
                <CForm method="post"
                       action={"https://secure.ogone.com/ncol/" + ogoneProperties.environment + "/orderstandard.asp"}
                       target={"_blank"}
                       id={"form" + invoice.id}
                       name={"form" + invoice.id}>
                    <CFormInput type="hidden" name="AMOUNT" value={getAmountCalculated(invoice)}/>
                    <CFormInput type="hidden" name="PSPID" value={ogoneProperties.pspid}/>
                    <CFormInput type="hidden" name="ORDERID" value={ogoneProperties.context + type + invoice.id}/>
                    <CFormInput type="hidden" name="CURRENCY" value="EUR"/>
                    <CFormInput type="hidden" name="LANGUAGE" value={LanguageUtils.getLocale()}/>
                    <CFormInput type="hidden" name="TITLE" value={getTitleByContext()}/>
                    <CFormInput type="hidden" name="BGCOLOR" value="#FFFFFF"/>
                    <CFormInput type="hidden" name="TXTCOLOR" value="#01308D"/>
                    <CFormInput type="hidden" name="TBLBGCOLOR" value="#EBEEFF"/>
                    <CFormInput type="hidden" name="TBLTXTCOLOR" value="#01308D"/>
                    <CFormInput type="hidden" name="BUTTONBGCOLOR" value="#FFFFFF"/>
                    <CFormInput type="hidden" name="BUTTONTXTCOLOR" value="#01308D"/>
                    <CFormInput type="hidden" name="FONTTYPE" value="Tahoma"/>
                    <CFormInput type="hidden" name="LOGO" value=""/>

                    {/* or dynamic template page */}
                    <CFormInput type="hidden" name="TP" value=""/>
                    {/* post-payment redirection */}
                    <CFormInput type="hidden" name="ACCEPTURL" value=""/>
                    <CFormInput type="hidden" name="DECLINEURL" value=""/>
                    <CFormInput type="hidden" name="EXCEPTIONURL" value=""/>
                    <CFormInput type="hidden" name="CANCELURL" value=""/>

                    {/* miscellanous */}
                    {/* TODO ogone return to create in react ? localhost/prod url ? */}
                    <CFormInput type="hidden" name="HOMEURL" value={getHomeUrl()}/>
                    <CFormInput type="hidden" name="CATALOGURL" value="none"/>
                    <CFormInput type="hidden" name="CN" value={user.firstName + " " + user.lastName}/>
                    <CFormInput type="hidden" name="EMAIL" value={user.email}/>
                    <CFormInput type="hidden" name="PM" value=""/>
                    <CFormInput type="hidden" name="BRAND" value=""/>
                    <CFormInput type="hidden" name="OWNERZIP" value={user.company.zipCode}/>
                    <CFormInput type="hidden" name="OWNERADDRESS"
                                value={user.company.address1 + " " + user.company.address2}/>

                    <CFormInput type="hidden" name="OWNERCTY" value={user.company.country.code}/>
                    <CFormInput type="hidden" name="OWNERTOWN"
                                value={user.company.city}/>
                    <CFormInput type="hidden" name="OWNERTELNO" value={GeneralUtils.filterDigits(user.company.phone)}/>
                    <CFormInput type="hidden" name="SHASIGN" value={sha1Key}/>
                    <CFormInput type="hidden" name="ALIAS" value=""/>
                    <CFormInput type="hidden" name="ALIASUSAGE" value=""/>
                    <CFormInput type="hidden" name="ALIASOPERATION" value=""/>
                    <CFormInput type="hidden" name="COM" value=""/>
                    <CFormInput type="hidden" name="COMPLUS" value=""/>
                    <CFormInput type="hidden" name="PARAMPLUS" value={getParamPlus()}/>
                    <CFormInput type="hidden" name="USERID" value=""/>
                    <div >
                        <CButton type="submit" value={t("Open")} size={"sm"} color={"info"}>{t("Open")}</CButton>
                    </div>
                </CForm>
        </>
    )
}

export default OgoneForm;