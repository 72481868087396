import {
    cilSearch,
    cilUser,
    cilBellExclamation,
    cilHamburgerMenu,
    cilCommentBubble,
    cilContact,
    cilFile,
    cilInbox,
    cilLibrary,
    cilLockLocked,
    cilReportSlash,
    cilSettings,
    cilSpeedometer,
    cilAddressBook,
    cilFolderOpen,
    cilFindInPage,
    cilSmilePlus,
    cilCommentSquare,
    cilStar,
    cilCode,
    cilActionRedo,
    cilPeople,
    cilWallet,
    cilEuro,
    cilCreditCard,
    cilMoney,
    cilChartLine,
    cilCalculator,
    cilColorBorder,
    cilFolder,
    cilGlobeAlt,
    cilMap,

} from "@coreui/icons";

export const icons = Object.assign({}, {
    cilSearch,
    cilUser,
    cilBellExclamation,
    cilHamburgerMenu,
    cilCommentBubble,
    cilContact,
    cilFile,
    cilInbox,
    cilLibrary,
    cilLockLocked,
    cilSettings,
    cilSpeedometer,
    cilAddressBook,
    cilFolderOpen,
    cilReportSlash,
    cilFindInPage,
    cilSmilePlus,
    cilCommentSquare,
    cilStar,
    cilCode,
    cilActionRedo,
    cilPeople,
    cilWallet,
    cilEuro,
    cilCreditCard,
    cilMoney,
    cilChartLine,
    cilCalculator,
    cilColorBorder,
    cilFolder,
    cilGlobeAlt,
    cilMap,
});