import React, {useEffect} from "react";
import {PartnerSubmissionView} from "../../../Data/view/PartnerSubmissionModel/PartnerSubmissionView";
import {Page} from "../../../Data/Page";
import {useNavigate, useParams} from "react-router-dom";
import {PartnerSubmissionRequestDTO} from "../../../Data/request/PartnerSubmissionRequestDTO";
import {BbxRequest} from "../../../Data/request/BbxRequest";
import {FolderRequestService} from "../../../Services/Supplier/FolderRequestService";
import {CBadge, CButton, CCard, CCardBody} from "@coreui/react";
import BbxTable from "../../../Shared/Components/Table/BbxTable";
import {DateUtils} from "../../../utils/DateUtils";
import {getBadgeColor, getBadgeContent} from "../../../Shared/Components/Badge/Badge";
import {useTranslation} from "react-i18next";
import {ExtranetFolderView} from "../../../Data/view/ExtranetFolderModel/ExtranetFolderView";
import {useTemplateUpdate} from "../../../Shared/Components/SidebarUpdateContextType/SidebarUpdateContextType";
import {BbxToastType} from "../../../CustomHooks/ToastCustomComponentProps/BbxToastType";

export default function FolderLeadList(): React.ReactElement {
    const {t} = useTranslation();
    const {showToast} = useTemplateUpdate();

    let {id} = useParams();
    const navigate = useNavigate();
    const [folder, setFolder] = React.useState<ExtranetFolderView>(new ExtranetFolderView());
    const [folderLeadList, setFolderLeadList] = React.useState<Page<PartnerSubmissionView>>(new Page<PartnerSubmissionView>());
    const [partnerSubmissionRequestDTO, setPartnerSubmissionRequestDTO] = React.useState<BbxRequest<PartnerSubmissionRequestDTO>>(new BbxRequest<PartnerSubmissionRequestDTO>(PartnerSubmissionRequestDTO, {data: {folderId: id}}));

    function getLeads() {
        partnerSubmissionRequestDTO.data.folderId = id ? parseInt(id) : 0;
        folderLeadList.isLoaded = false;
        FolderRequestService.getLeadsPerFolderId(partnerSubmissionRequestDTO).then((response) => {
            setFolderLeadList(response);
        }).catch(() => {
            showToast(BbxToastType.ERROR, "Error while fetching leads");
        });
    }

    function getFolder() {
        if (id) {
            FolderRequestService.getFolderById(parseInt(id)).then((response) => {
                setFolder(response);
            }).catch(() => {
                showToast(BbxToastType.ERROR, "Error while fetching folder name");
            });
        }
    }

    useEffect(() => {
        getLeads();
    }, [id, partnerSubmissionRequestDTO]);

    useEffect(() => {
        getFolder();

    }, [id]);


    function navigateToLead(leadId: number) {
        navigate(`/supplier/activity/leads/${leadId}`)
    }

    function getColumns() {
        return [
            {
                label: t("Lead number"), path: "", function: (lead: PartnerSubmissionView) => {
                    return (
                        <div className="p-3">
                            <p>{DateUtils.formatLocal(lead.partnerRfp.dateSubmission)}</p>
                            <p>ID: {lead.partnerRfp.id}</p>
                            <CButton color="info" className="mt-3"
                                     onClick={() => navigateToLead(lead.partnerRfp.id)}>{t("View lead")}</CButton>
                        </div>
                    )
                }
            },
            {
                label: t("Customer"), path: "", function: (lead: PartnerSubmissionView) => {
                    return (
                        <div className="p-3">
                            <p>{lead.partnerRfp.member.firstName} {lead.partnerRfp.member.lastName}</p>
                            <p>{lead.partnerRfp.member.company.phone} - {lead.partnerRfp.member.company.mobil}</p>
                            <p>{lead.partnerRfp.member.company.address1} - {lead.partnerRfp.member.company.address2}</p>
                            <p>{t(`${lead.partnerRfp.member.company.region}`)}</p>
                            <p>{lead.partnerRfp.member.email}</p>
                        </div>
                    );
                }
            },
            {
                label: t("Description"), path: "", function: (lead: PartnerSubmissionView) => {
                    return (
                        <div className="p-3">
                            <p>{lead.partnerRfp.projectDescription}</p>
                        </div>
                    );
                }
            },
            {
                label: t("Status"), path: "", function: (lead: PartnerSubmissionView) => {
                    return (
                        <div className="p-3 text-center">
                            <CBadge color={getBadgeColor(lead.status, [13, 48, 58])}>
                                {getBadgeContent(lead.status, [13, 48, 58])}
                            </CBadge>
                        </div>
                    );
                }
            }
        ];
    }

    function getSortByElements() {
        return [
            {label: "Date", value: "id"}
        ]
    }

    return (
        <div>
            <h1>{folder.name}</h1>
            <CCard>
                <CCardBody>
                    <BbxTable
                        request={partnerSubmissionRequestDTO}
                        page={folderLeadList}
                        columns={getColumns()}
                        onPageChange={(pageNumber) => {
                            setPartnerSubmissionRequestDTO({...partnerSubmissionRequestDTO, page: pageNumber});
                        }}
                        sortByElements={getSortByElements()}
                        onSortByChange={(value) => {
                            setPartnerSubmissionRequestDTO({...partnerSubmissionRequestDTO, sortColumn: value});
                        }}
                        onSortDirectionChange={(value) => {
                            setPartnerSubmissionRequestDTO({...partnerSubmissionRequestDTO, sortDirection: value});
                        }}
                        title={<>{t("Leads number") + ": " + folderLeadList.totalElements}</>}
                        mobileCardLayout={{
                            header: {
                                start: (lead: PartnerSubmissionView) => {
                                    return (
                                        <>
                                            <p>{DateUtils.formatLocal(lead.partnerRfp.dateSubmission)}</p>
                                            <p>{lead.partnerRfp.id}</p>
                                        </>
                                    )
                                },
                                end: (lead: PartnerSubmissionView) => {
                                    return (
                                        <CBadge
                                            color={getBadgeColor(lead.status, [13, 48, 58])}
                                        >
                                            {getBadgeContent(lead.status, [13, 48, 58])}
                                        </CBadge>
                                    )
                                }

                            },
                            body: (lead: PartnerSubmissionView) => {
                                return (
                                    <>
                                        <div className="lead-info mb-3">
                                            <p className="text-blue">{lead.partnerRfp.member.firstName} {lead.partnerRfp.member.lastName}</p>
                                            <p className="text-blue">{lead.partnerRfp.member.company.address1} - {lead.partnerRfp.member.company.address2}</p>
                                            <p className="text-blue">{t(`${lead.partnerRfp.member.company.region}`)}</p>
                                        </div>
                                        <div className="mb-3">
                                            <p style={{
                                                color: "var(--bobex-dark-blue)",
                                                fontWeight: "500"
                                            }}>{t("Description")}:</p>
                                            <p>{lead.partnerRfp.projectDescription}</p>
                                        </div>
                                    </>
                                )
                            },
                            footer: (lead: PartnerSubmissionView) => {
                                return (
                                    <div className={"text-center"}>
                                        <CButton color="info" onClick={() => navigateToLead(lead.partnerRfp.id)}>{t("View lead")}</CButton>
                                    </div>
                                )
                            }

                        }
                        }
                    />
                </CCardBody>
            </CCard>
        </div>
    );
}