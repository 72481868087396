import {BbxRequest} from "../Data/request/BbxRequest";

export default class RequestUtil {


    static resetRequest<T>(request: BbxRequest<T>) {
        request.page = 0;
        request.data = request.initialState;
        request.searchForm = request.initialState;

        return request;
    }

    static newSearchRequest<T>(request: BbxRequest<T>) {
        request.page = 0;
        request.data = request.searchForm;

        return request;
    }

    static switchPageRequest<T>(request: BbxRequest<T>, page: number) {
        request.page = page;
        request.searchForm = request.data;

        return request;

    }


    static switchPageSizeRequest<T>(request: BbxRequest<T>, size: number) {
        request.size = size;
        request.searchForm = request.data;

        return request;

    }

    static prepareRequest<T>(request: BbxRequest<T>) {
        return {
            page: request.page,
            size: request.size,
            sortColumn: request.sortColumn,
            sortDirection: request.sortDirection,
            data: request.data,
        }
    }
}