import {RouteUtils} from "./utils/RouteUtils";

export class ApiRoutes {

    static APP_API_URL_BACKEND = () => {

        const serverName = window.location.hostname;
        let urlSpring;

        if (serverName === "localhost") {
            urlSpring = "http://localhost:8080"
        } else if (serverName === "web009.bobex.com" || serverName.includes('my009')) {
            urlSpring = "https://web009.bobex.com"
        } else {
            switch (RouteUtils.getContext()) {
                case 'be':
                    urlSpring = "https://www.bobex.be";
                    break;
                case 'nl':
                    urlSpring = "https://www.bobex.nl";
                    break;
                case 'ci':
                    urlSpring = "https://www.conversion-impact.be";
                    break;
                case 'vbk':
                    urlSpring = "https://www.verbouwkosten.com";
                    break;
                default:
                    urlSpring = "https://www.bobex.be";
            }
        }

        if (RouteUtils.getContext() === 'be') {
            urlSpring += '/api-be';
        } else if (RouteUtils.getContext() === 'nl') {
            urlSpring += '/api-nl';
        } else if (RouteUtils.getContext() === 'ci') {
            urlSpring += '/api-ci';
        } else if (RouteUtils.getContext() === 'vbk') {
            urlSpring += '/api-vbk';
        } else {
            urlSpring += '/api-be';
        }

        return urlSpring;

    }
    static REACT_APP_BASE_URL_DEV_FRONTEND = () => {
        if (window.location.hostname.includes('localhost')) {
            return "http://localhost:3000";
        } else if (window.location.hostname.includes('web009')) {
            return "https://web009.bobex.com";
        }
    }

    static REACT_APP_BASE_URL_FRONTEND = () => {
        const serverName = window.location.hostname;

        let urlReact;


        if (serverName === "localhost") {
            urlReact = "http://localhost:3000"
        } else if (serverName === "web009.bobex.com") {
            urlReact = "https://web009.bobex.com"
        } else if (serverName.includes('my009')) {
            switch (RouteUtils.getContext()) {
                case 'be':
                    urlReact = "https://my009.bobex.be";
                    break;
                case 'nl':
                    urlReact = "https://my009.bobex.nl";
                    break;
                case 'ci':
                    urlReact = "https://my009.conversion-impact.be";
                    break;
                case 'vbk':
                    urlReact = "https://my009.verbouwkosten.com";
                    break;
                default:
                    urlReact = "https://my009.bobex.be";
            }
        } else {
            switch (RouteUtils.getContext()) {
                case 'be':
                    urlReact = "https://my.bobex.be";
                    break;
                case 'nl':
                    urlReact = "https://my.bobex.nl";
                    break;
                case 'ci':
                    urlReact = "https://my.conversion-impact.be";
                    break;
                case 'vbk':
                    urlReact = "https://my.verbouwkosten.com";
                    break;
                default:
                    urlReact = "https://my.bobex.be";
            }
        }

        return urlReact;
    }
    static REACT_APP_CONTEXT_URL_FRONTEND = () => {

        let urlReact;


        if (window.location.pathname.includes('extranet-be') ) {
            urlReact = '/extranet-be';
        } else if (window.location.pathname.includes('extranet-nl') ) {
            urlReact = '/extranet-nl';
        } else if (window.location.pathname.includes('extranet-ci') ) {
            urlReact = '/extranet-ci';
        } else if (window.location.pathname.includes('extranet-vbk') ) {
            urlReact = '/extranet-vbk';
        } else {
            urlReact = '';
        }

        return this.REACT_APP_BASE_URL_FRONTEND() + urlReact;

    }

    //region app url
    static APP_URL_LOGIN = this.REACT_APP_CONTEXT_URL_FRONTEND() + "/login";
    static REACT_APP_LOGIN_LANGUAGE = this.APP_API_URL_BACKEND() + "/v1/language";
    //endregion


    //region Spring rest Urls


    //region auth
    static REACT_APP_URL_AUTHENTICATE = this.APP_API_URL_BACKEND() + "/v1/auth/authenticate";
    static REACT_APP_URL_REFRESH_TOKEN = this.APP_API_URL_BACKEND() + "/v1/auth/refresh-token";
    static REACT_APP_URL_LOGOUT = this.APP_API_URL_BACKEND() + "/v1/auth/logout";
    static REACT_APP_URL_CHANGE_PASSWORD = this.APP_API_URL_BACKEND() + "/v1/auth/change-password";
    static REACT_APP_URL_BO_LOGIN = this.APP_API_URL_BACKEND() + "/v1/auth/bo-login";
    static REACT_APP_URL_FORGOT_PASSWORD = this.APP_API_URL_BACKEND() + "/v1/auth/forgot-password";
    ///api-be/v1/auth/verify/reset-password-token
    static REACT_APP_URL_RESET_PASSWORD_TOKEN = this.APP_API_URL_BACKEND() + "/v1/auth/verify/reset-password-token";
    //endregion

    //region supplier
    static REACT_APP_URL_SUPPLIER_DATA = this.APP_API_URL_BACKEND() + "/v1/supplier/";
    static REACT_APP_URL_SUPPLIER_ACCOUNT = this.REACT_APP_URL_SUPPLIER_DATA + "account";
    static REACT_APP_URL_SUPPLIER_EXPERT_BUSINESS_TYPES = this.REACT_APP_URL_SUPPLIER_DATA + "expert-business-types";
    static REACT_APP_URL_SUPPLIER_EXPERTROLE = this.REACT_APP_URL_SUPPLIER_DATA + "expertrole";
    static REACT_APP_URL_SUPPLIER_EXPERTROLE_INTEGRATION = this.REACT_APP_URL_SUPPLIER_DATA + "expertrole-integration";
    static REACT_APP_URL_SUPPLIER_LANGUAGE = this.REACT_APP_URL_SUPPLIER_DATA + "language";
    static REACT_APP_URL_SUPPLIER_TEAM_MEMBER = this.REACT_APP_URL_SUPPLIER_DATA + "team-member";
    static REACT_APP_URL_SUPPLIER_TEAM_MEMBER_USER_GROUP = this.REACT_APP_URL_SUPPLIER_DATA + "team-member-user-group";
    static REACT_APP_URL_SUPPLIER_TESTIMONIAL = this.REACT_APP_URL_SUPPLIER_DATA + "testimonial";
    static REACT_APP_URL_SUPPLIER_APIKEYS = this.REACT_APP_URL_SUPPLIER_DATA + "apikeys";
    static REACT_APP_URL_STATISTICS_LEAD_YEAR_MONTH = this.REACT_APP_URL_SUPPLIER_DATA + "leads/count/yearmonth/";
    static REACT_APP_URL_STATISTICS_LEAD_DAY = this.REACT_APP_URL_SUPPLIER_DATA + "leads/count/day/";
    static REACT_APP_URL_STATISTICS_LEAD_PER_REGION = this.REACT_APP_URL_SUPPLIER_DATA + "statistics/leads-per-region";
    static REACT_APP_URL_STATISTICS_LEAD_PER_BUSINESS_TYPE = this.REACT_APP_URL_SUPPLIER_DATA + "statistics/leads-per-business-type";
    static REACT_APP_URL_STATISTICS_LEAD_YEAR_MONTH_AND_SUBMISSION_STATUS = this.REACT_APP_URL_SUPPLIER_DATA + "statistics/leads-per-year-month-and-submission-status";
    static REACT_APP_URL_STATISTICS_LEAD_DAY_AND_SUBMISSION_STATUS = this.REACT_APP_URL_SUPPLIER_DATA + "statistics/leads-per-day-and-submission-status";
    static REACT_APP_URL_STATISTICS_SURVEYS = this.REACT_APP_URL_SUPPLIER_DATA + "statistics/surveys-statistics";
    static REACT_APP_URL_SUPPLIER_SURVEYS_BY_SUBMISSION_STATUS = this.REACT_APP_URL_SUPPLIER_DATA + "statistics/surveys-per-submission-status";
    static REACT_APP_URL_SUPPLIER_ACCOUNT_MANAGER = this.REACT_APP_URL_SUPPLIER_DATA + "account-manager";
    static REACT_APP_URL_SUPPLIER_INVOICES = this.REACT_APP_URL_SUPPLIER_DATA + "invoices";
    static REACT_APP_URL_SUPPLIER_CREDIT_NOTES = this.REACT_APP_URL_SUPPLIER_DATA + "credit-notes";
    static REACT_APP_URL_SUPPLIER_SURVEY = this.REACT_APP_URL_SUPPLIER_DATA + "surveys";
    static REACT_APP_URL_SUPPLIER_OPENING_HOURS = this.REACT_APP_URL_SUPPLIER_DATA + "opening-hours";
    static REACT_APP_URL_SUPPLIER_LEADS = this.REACT_APP_URL_SUPPLIER_DATA + "leads";
    static REACT_APP_URL_SUPPLIER_UPDATE_PROTEST = this.REACT_APP_URL_SUPPLIER_LEADS + "/update-protest/";
    static REACT_APP_URL_SUPPLIER_REMOVE_PROTEST_ATTACHMENT = this.REACT_APP_URL_SUPPLIER_LEADS + "/remove-protest-attachment/";
    static REACT_APP_URL_SUPPLIER_FOLDER = this.REACT_APP_URL_SUPPLIER_DATA + "folder";
    static REACT_APP_URL_SUPPLIER_ZIP = this.REACT_APP_URL_SUPPLIER_DATA + "zip";
    static REACT_APP_URL_SUPPLIER_OGONE = this.REACT_APP_URL_SUPPLIER_DATA + "ogone";
    static REACT_APP_URL_SUPPLIER_TEAMLEADER = this.REACT_APP_URL_SUPPLIER_DATA + "teamleader";


    //endregion

    //region buyer
    static REACT_APP_URL_BUYER_ACCOUNT = this.APP_API_URL_BACKEND() + "/v1/buyer/account";
    static REACT_APP_URL_BUYER_LEAD_LIST = this.APP_API_URL_BACKEND() + "/v1/buyer/leads";
    static REACT_APP_URL_BUYER_LEAD_ATTACHMENT = this.APP_API_URL_BACKEND() + "/v1/buyer/leads/upload-file";
    static REACT_APP_URL_BUYER_LEAD_REMOVE_ATTACHMENT = this.APP_API_URL_BACKEND() + "/v1/buyer/leads/delete-file";
    static REACT_APP_URL_BUYER_MEMBER_INFOS = this.APP_API_URL_BACKEND() + "/v1/buyer/";
    static REACT_APP_URL_BUYER_INTEREST = this.APP_API_URL_BACKEND() + "/v1/buyer/interests";
    static REACT_APP_URL_BUYER_SURVEYS_PER_SUPPLIER_ID = this.APP_API_URL_BACKEND() + "/v1/buyer/surveys/supplier";
    static REACT_APP_URL_BUYER_SURVEYS_PER_PARTNER_RFP_ID = this.APP_API_URL_BACKEND() + "/v1/buyer/surveys/partnerrfp";
    static REACT_APP_URL_BUYER_SURVEYS_UPDATE = this.APP_API_URL_BACKEND() + "/v1/buyer/surveys";
    static REACT_APP_URL_BUYER_LANGUAGE = this.APP_API_URL_BACKEND() + "/v1/buyer/language";
    static REACT_APP_URL_BUYER_FEEDBACK = this.APP_API_URL_BACKEND() + "/v1/buyer/feedback";
    static REACT_APP_URL_BUYER_BUSINESSES = this.APP_API_URL_BACKEND() + "/v1/buyer/businesses";

    //endregion

    //region affiliate
    static REACT_APP_URL_AFFILIATE_BANNER_LIST = this.APP_API_URL_BACKEND() + "/v1/affiliate/banners";
    static REACT_APP_URL_AFFILIATE_LANGUAGE = this.APP_API_URL_BACKEND() + "/v1/affiliate/language";
    static REACT_APP_URL_AFFILIATE_LEAD_LIST = this.APP_API_URL_BACKEND() + "/v1/affiliate/leads";
    static REACT_APP_URL_AFFILIATE_ACCOUNT = this.APP_API_URL_BACKEND() + "/v1/affiliate/account";
    static REACT_APP_URL_AFFILIATE_LANDINGPAGE = this.APP_API_URL_BACKEND() + "/v1/affiliate/landingpage";
    static REACT_APP_URL_AFFILIATE_COST = this.APP_API_URL_BACKEND() + "/v1/affiliate/cost";
    static REACT_APP_URL_AFFILIATE_PRICING = this.APP_API_URL_BACKEND() + "/v1/affiliate/price-per-lead";
    static REACT_APP_URL_MAILING_KIT = this.APP_API_URL_BACKEND() + "/v1/affiliate/mailing-kit";
    //endregion

    //region general

    static REACT_APP_URL_GENERAL_REGION = this.APP_API_URL_BACKEND() + "/v1/region";
    //endregion

    //endregion

}