//React and Coreui Imports
import {CBadge, CNavItem} from '@coreui/react';
import CIcon from '@coreui/icons-react';

//Types
import {NavGroup} from '../../../Shared/Interfaces/Navigation.interface';
import {FolderRequestService} from "../../../Services/Supplier/FolderRequestService";
import {BbxRequest} from "../../../Data/request/BbxRequest";
import {ExtranetFolderView} from "../../../Data/view/ExtranetFolderModel/ExtranetFolderView";
import {useEffect, useState} from "react";
import {useTemplateUpdate} from "../../../Shared/Components/SidebarUpdateContextType/SidebarUpdateContextType";
import {BbxToastType} from "../../../CustomHooks/ToastCustomComponentProps/BbxToastType";

/**
 * Const Nav
 * Creates an array of objects that are used to create the sidebar navigation.
 * @NavGroup is an interface that defines the properties of the toggler (main categories in sidebar).
 * @NavItem is an interface that defines the properties of the navigation links (subcategories in sidebar).
 */
const SupplierNav = () => {
    const {triggerRefresh, setFolderView} = useTemplateUpdate(); // Use the context

    const [folders, setFolders] = useState([] as ExtranetFolderView[]);
    const {showToast} = useTemplateUpdate();


    useEffect(() => {
        // Function to fetch folders
        const fetchFolders = () => {
            const request = new BbxRequest<Object>(Object);
            request.sortColumn = "name"
            request.sortDirection = "asc"
            request.size = 10000;
            FolderRequestService.getFolders(request)
                .then(response => {
                    setFolders(response.content);
                    setFolderView(response);
                })
                .catch(() => {
                    showToast(BbxToastType.ERROR, "Error while getting folders")
                });
        };

        fetchFolders(); // Initial fetch

    }, [triggerRefresh]); // Re-fetch when triggerRefresh changes


    const getFolderItems = () => {
        return folders.map(folder => ({

            component: CNavItem,
            name: folder.name,
            icon: CIcon,
            iconProps: {
                icon: 'cilFolderOpen',
                className: 'buyerNav-icon me-2 ms-3'
            },
            to: `/supplier/activity/folder/${folder.id}`,
            badge: <CBadge color="primary ms-auto">{folder.partnerSubmissionCount}</CBadge>
        }));
    };

    return (
        [
            {
                name: 'Supplier.Nav',
                type: "sidebarHeader",
                items: []
            },
            {
                name: 'Supplier.Nav.Activity',
                items: [
                    {
                        name: 'Leads',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilAddressBook',
                            className: 'buyerNav-icon me-2',
                        },
                        to: '/supplier/activity/leads',
                    },
                    {
                        name: 'Manage folders',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilFolder',
                            className: 'buyerNav-icon me-2'
                        },
                        to: '/supplier/activity/folders',
                    },
                    ...getFolderItems(),
                ]
            },
            {
                name: 'Achievements',
                teamMemberAllowed: false,
                items: [
                    {
                        name: 'Charts',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilSpeedometer',
                            className: 'buyerNav-icon me-2'
                        },
                        to: '/supplier/achievements/graphics',
                        teamMemberAllowed: false,
                    },
                    {
                        name: 'Reviews',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilSmilePlus',
                            className: 'buyerNav-icon me-2'
                        },
                        to: '/supplier/achievements/statistics-and-ratings',
                        teamMemberAllowed: false,
                    },
                  /*  {
                        name: 'Testimonial',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilFile',
                            className: 'buyerNav-icon me-2'
                        },
                        to: '/supplier/achievements/testimonial',
                        teamMemberAllowed: false
                    }*/
                ]
            },
            {
                name: 'Supplier.Nav.Account',
                items: [
                    {
                        name: 'Categories and regions',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilMap',
                            className: 'buyerNav-icon me-2',
                        },
                        to: '/supplier/activity/categories-and-work-areas',
                        teamMemberAllowed: false
                    },
                    {
                        name: 'Invoices',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilMoney',
                            className: 'buyerNav-icon me-2'
                        },
                        to: '/supplier/account/invoices',
                        teamMemberAllowed: false
                    },
                    {
                        name: 'CreditNotes',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilCalculator',
                            className: 'buyerNav-icon me-2'
                        },
                        to: '/supplier/account/credit-notes',
                        teamMemberAllowed: false
                    },
                    {
                        name: 'Team',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilPeople',
                            className: 'buyerNav-icon me-2'
                        },
                        to: '/supplier/account/team-members',
                        teamMemberAllowed: false
                    },
                    {
                        name: 'Supplier.Nav.Contact.details',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilUser',
                            className: 'buyerNav-icon me-2'
                        },
                        to: '/supplier/account/contact-details',
                        teamMemberAllowed: false
                    },
                    {
                        name: 'Profile',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilSettings',
                            className: 'buyerNav-icon me-2'
                        },
                        to: '/supplier/account/profile',
                        teamMemberAllowed: false
                    },
                    {
                        name: 'Supplier.Nav.Password',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilLockLocked',
                            className: 'buyerNav-icon me-2'
                        },
                        to: '/supplier/account/password',
                    },
                ]
            },
            {
                name: 'Data management',
                teamMemberAllowed: false,
                items: [
                    // {
                    //     name: 'API key',
                    //     icon: CIcon,
                    //     iconProps: {
                    //         icon: 'cilCode',
                    //         className: 'buyerNav-icon me-2'
                    //     },
                    //     to: '/supplier/data-management/api',
                    //     teamMemberAllowed: false
                    // },
                    {
                        name: 'Integration',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilActionRedo',
                            className: 'buyerNav-icon me-2'
                        },
                        to: '/supplier/data-management/integration',
                        teamMemberAllowed: false
                    }
                ]
            },
            {
                name: 'Buyer.Nav',
                type: "sidebarHeader",
                items: [],
                className: '',
                teamMemberAllowed: false
            },
            {
                name: 'Supplier.Nav.Projects',
                teamMemberAllowed: false,
                items: [

                    {
                        name: 'Supplier.Nav.Projects',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilLibrary',
                            className: 'buyerNav-icon me-2',
                        },
                        to: '/supplier/projects/overview',
                        teamMemberAllowed: false
                    },

                    {
                        name: 'New project',
                        icon: CIcon,
                        iconProps: {
                            icon: 'cilFile',
                            className: 'buyerNav-icon me-2'
                        },
                        to: '/supplier/projects/new-project',
                        teamMemberAllowed: false
                    }

                ]
            },

        ] as NavGroup[]
    )
};

export default SupplierNav;
