import Axios from "./AxiosInterceptorsService";
import {requestService} from "./RequestService";
import {ChangePasswordRequestDTO} from "../../Data/request/ChangePasswordRequest";
import {Buffer} from "buffer";
import {ApiRoutes} from "../../ApiRoutes";
import {RouteUtils} from "../../utils/RouteUtils";
import {SelectOptionDTO} from "../../Data/SelectOptionDTO";
import {AccountManagerView} from "../../Data/view/MemberModel/AccountManagerView";
import {SelectOptionView} from "../../Data/view/SelectOption/SelectOptionView";

const login = (credentials: any) => {

    if (!ApiRoutes.REACT_APP_URL_AUTHENTICATE) {
        throw new Error("REACT_APP_API_URL_BACKEND is not defined");
    }
    return requestService.post(ApiRoutes.REACT_APP_URL_AUTHENTICATE, credentials);
};

let saveToken = (accessToken: string) => {
    localStorage.setItem('access_token', accessToken);
}

let saveRefreshToken = (refreshToken: string) => {
    localStorage.setItem('refresh_token', refreshToken);
}

let saveLanguageLocal = (language: any, i18n: any) => {

    localStorage.setItem('language-' + RouteUtils.getContext(), Buffer.from(JSON.stringify(language)).toString("base64"));

    i18n.changeLanguage(language.name);
}

let saveUser = (user: any) => {
    localStorage.setItem('user-' + RouteUtils.getContext(), Buffer.from(JSON.stringify(user)).toString("base64"));
}

let getAccessToken = () => {
    return localStorage.getItem('access_token');
}

let getRefreshToken = () => {
    return localStorage.getItem('refresh_token');
}

let saveRedirectAfterLogin = (location: string) => {
    // Save the current location to redirect to after login
    return localStorage.setItem('redirectAfterLogin', location);
}

let getRedirectAfterLogin = () => {
    return localStorage.getItem('redirectAfterLogin');
}

let getUser = () => {
    const storageUser = localStorage.getItem('user-' + RouteUtils.getContext());

    let decodedStorageUser = null;

    if (storageUser !== null) {
        decodedStorageUser = Buffer.from(storageUser, 'base64').toString();
        decodedStorageUser = JSON.parse(decodedStorageUser);
    }

    return decodedStorageUser;
}

let getLanguageLocalStorage = () => {
    const storageLanguage = localStorage.getItem('language-' + RouteUtils.getContext());

    let decodedStorageLanguage = null;

    if (storageLanguage !== null) {
        decodedStorageLanguage = Buffer.from(storageLanguage, 'base64').toString();
        decodedStorageLanguage = JSON.parse(decodedStorageLanguage);
    }

    return decodedStorageLanguage;
}

let updateUserLanguageLocal = (newLanguage: string, newLanguageID: number, i18n: any) => {
    const storageUser = localStorage.getItem('user-' + RouteUtils.getContext());

    let decodedStorageUser = null;

    if (storageUser !== null) {
        decodedStorageUser = Buffer.from(storageUser, 'base64').toString();
        decodedStorageUser = JSON.parse(decodedStorageUser);

        // Mettez à jour les valeurs
        decodedStorageUser.language = newLanguage;
        decodedStorageUser.languageId = newLanguageID;

        // Réencodez et stockez à nouveau dans le stockage local
        const updatedUser = JSON.stringify(decodedStorageUser);
        const encodedUser = Buffer.from(updatedUser).toString('base64');
        localStorage.setItem('user-' + RouteUtils.getContext(), encodedUser);
    }
}

let updateLanguageLocalToken = (newLanguage: string, newLanguageID: number, i18n: any) => {
    const storageLanguage = localStorage.getItem('language-' + RouteUtils.getContext());

    let decodedStorageLanguage = null;

    if (storageLanguage !== null) {
        decodedStorageLanguage = Buffer.from(storageLanguage, 'base64').toString();
        decodedStorageLanguage = JSON.parse(decodedStorageLanguage);

        // Update values
        decodedStorageLanguage.id = newLanguageID;
        decodedStorageLanguage.name = newLanguage;

        // Re-encode and store back in local storage
        const updatedLanguage = JSON.stringify(decodedStorageLanguage);
        const encodedLanguage = Buffer.from(updatedLanguage).toString('base64');
        localStorage.setItem('language-' + RouteUtils.getContext(), encodedLanguage);

        i18n.changeLanguage(newLanguage);
    }
}

//TODO : refactor accountaffiliate and accountsupplier here
let getLocal = () => {
    return getUser().locale;
}

let getLanguage = () => {
    return getLocal().split('_')[0];
}
let getId = () => {
    return getUser().userID;
}

let isLogged = () => {
    let accessToken = localStorage.getItem('access_token');
    return !!accessToken;
}

const refreshToken = async () => {

    if (!ApiRoutes.REACT_APP_URL_REFRESH_TOKEN) {
        throw new Error("REACT_APP_URL_REFRESH_TOKEN is not defined");
    }

    return await requestService.get(ApiRoutes.REACT_APP_URL_REFRESH_TOKEN, {}, {
        Accept: "application/json",
        Authorization: 'Bearer ' + getRefreshToken(),
    }).then((response) => {
        if (response.status === 200) {
            saveToken(response.data.access_token);
            return response.data.access_token;
        } else {
            throw new Error("Error during token refresh");
        }
    }).catch((error) => {
        throw new Error("Error during token refresh");
    });


};

async function logout() {

    if (!ApiRoutes.REACT_APP_URL_LOGOUT) {
        throw new Error("REACT_APP_URL_LOGOUT is not defined");
    }
    return await requestService.get(ApiRoutes.REACT_APP_URL_LOGOUT, {})
        .then((response) => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem("user-" + RouteUtils.getContext());
            localStorage.setItem("redirect_to", window.location.href);
        })
        .catch((error) => {
            console.error('Error during server logout: ', error);
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem("user-" + RouteUtils.getContext());
            localStorage.setItem("redirect_to", window.location.href);
        }).finally(() => {
                window.location.href = ApiRoutes.APP_URL_LOGIN ?? "";
            }
        )

}

async function changePassword(changePasswordRequestDTO: ChangePasswordRequestDTO) {
    const user = getUser();

    if (user === null) {
        if (!ApiRoutes.REACT_APP_URL_CHANGE_PASSWORD) {
            throw new Error("REACT_APP_URL_CHANGE_PASSWORD is not defined");
        }

        return await requestService.put(ApiRoutes.REACT_APP_URL_CHANGE_PASSWORD, changePasswordRequestDTO).then((response) => {
            return response as any;
        });
    }

    if (user.authority === "buyer") {
        if (!ApiRoutes.REACT_APP_URL_BUYER_ACCOUNT) {
            throw new Error("REACT_APP_URL_BUYER_ACCOUNT is not defined");
        }

        return await requestService.put(ApiRoutes.REACT_APP_URL_BUYER_ACCOUNT + "/change-password", changePasswordRequestDTO).then((response) => {
            return response as any;
        });
    } else if (user.authority === "supplier") {

        if (!ApiRoutes.REACT_APP_URL_SUPPLIER_ACCOUNT) {
            throw new Error("REACT_APP_URL_SUPPLIER_ACCOUNT is not defined");
        }

        return await requestService.put(ApiRoutes.REACT_APP_URL_SUPPLIER_ACCOUNT + "/change-password", changePasswordRequestDTO).then((response) => {
            return response as any;
        });

    } else if (user.authority === "affiliate") {

        if (!ApiRoutes.REACT_APP_URL_AFFILIATE_ACCOUNT) {
            throw new Error("REACT_APP_URL_AFFILIATE_ACCOUNT is not defined");
        }

        return await requestService.put(ApiRoutes.REACT_APP_URL_AFFILIATE_ACCOUNT + "/change-password", changePasswordRequestDTO).then((response) => {
            return response as any;
        });
    }

}

async function changeLanguage(selectOptionDTO: SelectOptionView) {
    const user = getUser();

    if (user.authority === "supplier") {
        if (!ApiRoutes.REACT_APP_URL_SUPPLIER_ACCOUNT) {
            throw new Error("REACT_APP_URL_SUPPLIER_ACCOUNT is not defined");
        }

        return await requestService.put(ApiRoutes.REACT_APP_URL_SUPPLIER_ACCOUNT + "/change-language", selectOptionDTO).then((response) => {
            return response as any;
        });
    } else if (user.authority === "affiliate") {

        if (!ApiRoutes.REACT_APP_URL_AFFILIATE_ACCOUNT) {
            throw new Error("REACT_APP_URL_AFFILIATE_ACCOUNT is not defined");
        }

        return await requestService.put(ApiRoutes.REACT_APP_URL_AFFILIATE_ACCOUNT + "/change-language", selectOptionDTO).then((response) => {
            return response as any;
        });
    }

}

async function uploadLogo(file: any) {
    return await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_ACCOUNT + "/upload-logo", file).then((response) => {
        return response as any;
    });
}

async function uploadBanner(file: any) {
    return await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_ACCOUNT + "/upload-banner", file).then((response) => {
        return response as any;
    });
}

async function uploadPortFolio(file: any) {
    return await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_ACCOUNT + "/upload-portfolio", file).then((response) => {
        return response as any;
    });
}

async function deleteBanner() {
    return await requestService.del(ApiRoutes.REACT_APP_URL_SUPPLIER_ACCOUNT + "/delete-banner", {}).then((response) => {
        return response as any;
    });
}

async function deletePortFolio(id: number) {

    return await requestService.del(ApiRoutes.REACT_APP_URL_SUPPLIER_ACCOUNT + `/delete-portfolio/${id}`, {}).then((response) => {
        return response as any;
    });
}

async function checkBanner() {
    return await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_ACCOUNT + "/check-banner", {}).then((response) => {
        return response as any;
    });
}

const boLogin = async (token: string) => {

    return await requestService.get(ApiRoutes.REACT_APP_URL_BO_LOGIN + `/${token}`, {}).then((response) => {
        return response;
    });
};

export async function forgotPassword(email: string): Promise<any> {

    return await Axios.post(ApiRoutes.REACT_APP_URL_FORGOT_PASSWORD, {}, {
        params: {
            email: email
        }
    }).then((response) => {
        return response;
    });
}

const checkIfEmailVerificationTokenIsValid = async (token: string) => {
    return await requestService.get(ApiRoutes.REACT_APP_URL_RESET_PASSWORD_TOKEN + `/${token}`, {}).then((response) => {
        return response;
    });
};

async function getAccountManager(): Promise<AccountManagerView> {
    return await requestService.get(ApiRoutes.REACT_APP_URL_AFFILIATE_ACCOUNT + "/account-manager", {})
        .then((response) => {
            return response.data;
        });
}

function storeCompanyLogo(url: any) : void{
    let user = getUser()
    user.companyPictureUrl= url
    saveUser(user)
}

export const accountService = {
    login,
    saveToken,
    saveRefreshToken,
    refreshToken,
    isLogged,
    getAccessToken,
    getRefreshToken,
    getUser,
    logout,
    changePassword,
    boLogin,
    forgotPassword,
    checkIfEmailVerificationTokenIsValid,
    saveUser,
    getLocal,
    getLanguage,
    getId,
    changeLanguage,
    updateUserLanguageLocal,
    updateLanguageLocalToken,
    saveLanguageLocal,
    getLanguageLocalStorage,
    uploadLogo,
    uploadBanner,
    deleteBanner,
    checkBanner,
    uploadPortFolio,
    deletePortFolio,
    getAccountManager,
    saveRedirectAfterLogin,
    getRedirectAfterLogin,
    storeCompanyLogo,
};