export class PartnerRfpRequestDTO{
    id: number;
    projectName: string;
    name: string;
    submissionDateStart: Date | null;
    submissionDateEnd: Date | null;
    creationDateStart: Date | null;
    creationDateEnd: Date | null;
    businessTypeIDs: number[];
    telNumber: string;
    gstNumber: string;
    address: string;
    address2: string;
    email: string;
    description: string;
    status: string;
    clickId: string;
    languageID: number;
    utmSource: string[];
    regions: number[];

    constructor(data?: any) {
        this.id = data?.id ?? 0;
        this.projectName = data?.projectName ?? "";
        this.name = data?.name ?? "";
        this.submissionDateStart = data?.submissionDateStart ?? null;
        this.submissionDateEnd = data?.submissionDateEnd ?? null;
        this.creationDateStart = data?.creationDateStart ?? null;
        this.creationDateEnd = data?.creationDateEnd ?? null;
        this.businessTypeIDs = data?.businessTypeIDs ?? [];
        this.telNumber = data?.telNumber ?? "";
        this.gstNumber = data?.gstNumber ?? "";
        this.address = data?.address ?? "";
        this.address2 = data?.address2 ?? "";
        this.email = data?.email ?? "";
        this.description = data?.description ?? "";
        this.status = data?.status ?? "";
        this.clickId = data?.clickId ?? "";
        this.languageID = data?.languageID ?? 0;
        this.utmSource = data?.utmSource ?? [];
        this.regions = data?.regions ?? [];
    }

}