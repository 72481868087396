import Axios from "./AxiosInterceptorsService";
import {AxiosError} from "axios";

const post = async (url: string, data: any, params: Record<string, any> = {}) => {

    try {
        return await Axios.post(url, data, params);
    } catch (e: any) {
        throw e;

    }
}

const get = async (url: string, data: any = {}, header: any = {}) => {

    try {
        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...header
        }
        return await Axios.get(url, {
            signal: data.signal,
            params: data,
            headers: headers,

        });
    } catch (e: any) {
        throw e;
    }
}

const put = async (url: string, data: any, config = {} as any) => {

    try {
        return await Axios.put(url, data, config);
    } catch (e: any) {
        throw e;
    }
}

const del = async (url: string, data: any) => {

    try {
        return await Axios.delete(url, data);
    } catch (e: any) {
        throw e;
    }
}

export const requestService = {
    post,
    get,
    put,
    del
}