import React from "react";
import {Route} from "react-router-dom";

//Types
import {Routes} from "../../../Shared/Interfaces/Routes.interface";
import Invoices from "../Invoices/Invoices";
import Folders from "../Folders/Folders";
import FolderLeadList from "../FolderLeadList/FolderLeadList";
import TeamLeaderAuthorizer from "../Integration/Integrations/TeamLeaderAuthorizer";

//Components
const LeadsSearch = React.lazy(() => import("../Leads/LeadsOverview/AdvancedSearch/AdvancedSearch"));
const FAQ = React.lazy(() => import("../../../Shared/Components/FAQ/FAQ"));
const LeadDetailedOverview = React.lazy(() => import("../Leads/LeadDetailedOverview/LeadDetailedOverview"));
const ContactDetails = React.lazy(() => import("../ContactDetails/ContactDetails"))
const SupplierTestimonials = React.lazy(() => import("../SupplierTestimonial/SupplierTestimonial"));
const SupplierPassword = React.lazy(() => import("../Password/Password"));
const ProtectedRoute = React.lazy(() => import("../../../Shared/Components/ProtectedRoute/ProtectedRoute"));
const NotFound = React.lazy(() => import("../../../Shared/Components/PageNotFound/PageNotFound"));
const NoAccess = React.lazy(() => import("../../../Shared/Components/NoAccessToPage/NoAccessToPage"));
const ApiKey = React.lazy(() => import("../ApiKey/ApiKey"));
const SupplierGraphics = React.lazy(() => import("../Charts/Charts"));
const SupplierStatistics = React.lazy(() => import("../Statistics/Statistics"));
const SupplierIntegration = React.lazy(() => import("../Integration/Integration"));
const manageTeamMember = React.lazy(() => import("../../Supplier/TeamMember/ManageTeamMember"));
const Settings = React.lazy(() => import("../ExpertBusinessType/ExpertBusinessType"));
const SupplierLeadsOverview = React.lazy(() => import("../Leads/LeadsOverview/LeadsOverview"));
const CreditNotes = React.lazy(() => import("../CreditNotes/CreditNotes"));
const Profile = React.lazy(() => import("../Profile/Profile"));
const Projects = React.lazy(() => import('../../Buyer/Projects/ProjectsOverview/ProjectsOverview'));
const DetailedProject = React.lazy(() => import('../../Buyer/Projects/ProjectDetailedOverview/ProjectDetailedOverview'));
const SuppliersRating = React.lazy(() => import('../../Buyer/SuppliersRating/SuppliersRatingOverview/SuppliersRatingOverview'));
const NewRequest = React.lazy(() => import('../../Buyer/NewRequest/NewRequest'));

export const SuppliersRoutes: Routes[] = [

    {path: 'activity/leads', name: 'Leads', element: SupplierLeadsOverview},
    {path: 'activity/leads/search', name: 'Search results', element: SupplierLeadsOverview},
    {path: 'activity/leads/:id', name: 'Detailed project', element: LeadDetailedOverview},
    {path: 'activity/folders', name: 'Manage folders', element: Folders},
    {path: 'activity/folder/:id', name: 'Manage folders', element: FolderLeadList},
    {path: 'activity/categories-and-work-areas', name: 'Categories & regions', element: Settings},
    // {path: 'data-management', name: 'Data management', element: ApiKey},
    // {path: 'data-management/api', name: 'API key', element: ApiKey},
    {path: 'data-management/integration', name: 'Integration', element: SupplierIntegration},
    {path: 'achievements/statistics-and-ratings', name: 'Statistics & ratings', element: SupplierStatistics},
    {path: 'achievements/graphics', name: 'Charts', element: SupplierGraphics},
/*
    {path: 'achievements/testimonial', name: 'Testimonial', element: SupplierTestimonials},
*/
    {path: 'account/contact-details', name: 'Contact details', element: ContactDetails},
    {path: 'account/password', name: 'Password', element: SupplierPassword},
    {path: 'account/invoices', name: 'Invoices', element: Invoices},
    {path: 'account/credit-notes', name: 'CreditNotes', element: CreditNotes},
    {path: 'account/team-members', name: 'Team', element: manageTeamMember},
    {path: 'account/profile', name: 'Profile', element: Profile},
    {path: 'help', name: 'FAQ', element: FAQ},
    {path: 'no-access', name: '', element: NoAccess},
    {path: '*', name: '', element: NotFound},
    {path: 'customers/search', name: 'Advanced search', element: LeadsSearch},
    {path: 'projects/overview', name: 'Overview', element: Projects},
    {path: 'projects/overview/:id', name: 'Project details', element: DetailedProject},
    {path: 'projects/overview/:id/review', name: 'My suppliers', element: SuppliersRating},
    {path: 'projects/new-project', name: 'New project', element: NewRequest},
    {path: "teamleader/teamleader_authorize/:deal" , name: "Teamleader authorize", element: TeamLeaderAuthorizer}
];



export const BobexSuppliersRoutes =


    SuppliersRoutes.map((route, index) => {
        return (
            route.element &&
            <Route key={index} element={<ProtectedRoute/>}>
                <Route path={route.path} element={(<route.element/>)}/>
            </Route>
        )
    });