import {RouteUtils} from "./RouteUtils";
import {accountService} from "../Services/Helpers/AccountService";

function getLanguageLocalByCode(id: number) {
    switch (id) {
        case 3:
            return "en_US";
        case 5:
            return "fr_BE";
        case 2:
            if (RouteUtils.getContext() === 'be' || RouteUtils.getContext() === 'ci') {
                return "nl_BE";
            } else {
                return "nl_NL";
            }
        default:
            return "en_US";
    }
}

function getLanguageById(id: number) {
    switch (id) {
        case 3:
            return "En";
        case 5:
            return "Fr";
        case 2:
            return "Nl";
        default:
            return "En";
    }
}

function getPricePerLeadLanguage(id:number){
    switch (id) {
        case -1:
            return "Two languages";
        case 3:
            return "English";
        case 5:
            return "French";
        case 2:
            return "Dutch";
        default:
            return "Dutch";
    }

}

function getLanguage() {
    let locale = getLocale(false);
    if (locale) {
        return locale.split('_')[0];
    }
    return 'nl';

}

function getLocale(isDash: boolean = false) {

    let languageReturn

    const user = accountService.getUser();
    const language = accountService.getLanguageLocalStorage();

    if (language) {
        languageReturn = language.name;
    } else if (user) {
        languageReturn = user.locale;
    } else {
        if (RouteUtils.getContext() === 'be' || RouteUtils.getContext() === 'ci') {
            languageReturn = 'nl_BE';
        } else {
            languageReturn = 'nl_NL';
        }
    }
    if (isDash) {
        return languageReturn.replace('_', '-');
    } else {
        return languageReturn;
    }
}

function getPrivacyPageByLanguage(language: string, context: string) {
    if (context === 'api-be') {
        if (language === 'fr_BE') {
            return 'https://www.bobex.be/fr-be/protection-de-la-vie-privee/';
        } else {
            return 'https://www.bobex.be/nl-be/privacybeleid/';
        }
    } else if (context === 'api-nl') {
        return 'https://www.bobex.nl/nl-nl/privacybeleid/';
    } else if (context === 'api-ci') {
        return 'https://www.conversion-impact.be/privacy-policy.html';
    }
}

export const LanguageUtils = {
    getLanguageLocalByCode,
    getLanguageById,
    getLocale,
    getLanguage,
    getPrivacyPageByLanguage,
    getPricePerLeadLanguage
}