import {requestService} from "../Helpers/RequestService";
import {ApiRoutes} from "../../ApiRoutes";
import {TeamLeaderRequestDTO} from "../../Data/request/TeamLeaderRequestDTO";

export async function getTeamLeaderData(teamLeaderRequestDTO : TeamLeaderRequestDTO): Promise<string> {

    try {
       const response = await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_TEAMLEADER + `/`, teamLeaderRequestDTO);

       return response.data as string;

    } catch (error) {
        throw new Error("Error getting data from teamleader");
    }
}

export async function teamLeaderAuthorize(teamLeaderRequestDTO : TeamLeaderRequestDTO): Promise<void> {

    try {
        await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_TEAMLEADER + `/teamleader_authorize`, teamLeaderRequestDTO);
    } catch (error) {
        throw new Error("Error authorizing team leader");
    }
}

export const TeamLeaderService = {
    getTeamLeaderData,
    teamLeaderAuthorize
}