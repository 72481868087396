import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import EnglishFlag from "../../../Assets/img/en_UK.png";
import FrenchFlag from "../../../Assets/img/fr_BE.png";
import DutchFlag from "../../../Assets/img/nl_NL.png";
import DutchBEFlag from "../../../Assets/img/nl_BE.png";
import {LanguageService} from "../../../Services/Supplier/LanguageService";
import {accountService} from "../../../Services/Helpers/AccountService";
import Select, {ActionMeta, components, MultiValue, SingleValue, SingleValueProps} from 'react-select';
import {RouteUtils} from "../../../utils/RouteUtils";
import {LanguageUtils} from "../../../utils/LanguageUtils";
import {LanguageView} from "../../../Data/view/LanguageModel/LanguageView";


const LanguageSwitcher: React.FC = () => {
    const {t, i18n} = useTranslation();
    const user = accountService.getUser();
    const language = accountService.getLanguageLocalStorage();

    const [selectedLanguage, setSelectedLanguage] = useState<LanguageView | null>(null);
    const [languages, setLanguages] = useState<LanguageView[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);

    const getLanguages = async () => {
        let languagesData: LanguageView[] = [];

        try {

            if (user) {
                if (user.authority === "supplier") {
                    languagesData = await LanguageService.getLanguagesSupplier();
                } else if (user.authority === "affiliate") {
                    languagesData = await LanguageService.getLanguagesAffiliate();
                } else if (user.authority === "buyer") {
                    languagesData = await LanguageService.getLanguagesBuyer();
                }
            } else {
                languagesData = await LanguageService.getLanguagesForLogin();
            }

            setLanguages(languagesData);

            let userLanguage;

            if (language !== null) {
                userLanguage = languagesData.find(lang => lang.id === language.id);
            } else {
                if (user) {
                    userLanguage = languagesData.find(lang => lang.id === user.languageId);
                } else {
                    userLanguage = languagesData.find(lang => lang.id === 2);
                }
            }

            if (userLanguage) {
                setSelectedLanguage(userLanguage);
            }

            setLoading(false);
        } catch (error) {
            console.error("Error fetching languages:", error);
        }
    };


    useEffect(() => {
        getLanguages()
    }, []);

    const onLanguageChange = (
        selectedOption: SingleValue<LanguageView> | MultiValue<LanguageView>,
        action: ActionMeta<LanguageView>
    ) => {
        setLoading(true);
        if (selectedOption) {
            const selectedLanguage = Array.isArray(selectedOption)
                ? selectedOption[0]
                : (selectedOption as LanguageView);

            setSelectedLanguage(selectedLanguage);

            let languageCode = LanguageUtils.getLanguageLocalByCode(selectedLanguage.id);

            const language = {
                id: selectedLanguage.id,
                name: languageCode
            };

            if (user) {
                if (languageCode !== user.language) {
                    if (accountService.getLanguageLocalStorage() === null) {
                        accountService.saveLanguageLocal(language, i18n);
                    } else {
                        accountService.updateLanguageLocalToken(languageCode, selectedLanguage.id, i18n);
                    }
                } else {
                    localStorage.removeItem('language-' + RouteUtils.getContext());
                    i18n.changeLanguage(languageCode);
                }
            } else {
                if (accountService.getLanguageLocalStorage() === null) {
                    accountService.saveLanguageLocal(language, i18n);
                    i18n.changeLanguage(languageCode);
                } else {
                    accountService.updateLanguageLocalToken(languageCode, selectedLanguage.id, i18n);
                    i18n.changeLanguage(languageCode);
                }
            }
        }
        setLoading(false);
        window.location.reload();
    }

    const CustomOption = (props: any) => (
        <components.Option {...props}>
            <img src={getLogo(props.data.id)} alt={t(`${props.data.code}`)}
                 style={{width: '20px', marginRight: '10px'}}/>
            {t(`${props.data.code}`)}
        </components.Option>
    );

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            backgroundColor: "var(--icon-blue)",
            height: "2.5rem",
            width: '3.8rem',
            borderRadius: "25px",
            border: 'none',
            ':hover': {
                background: 'var(--very-light-blue)',
            },
            ':active': {
                boxShadow: "unset !important",
            },
        }),
        indicatorSeparator: (provided: any) => ({
            ...provided,
            display: 'none',
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            display: 'none',
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            background: state.isSelected ? 'white' : 'transparent',
            color: state.isSelected ? 'black' : 'black',
            ':hover': {
                background: '#e5f2f7'
            },
        }),
        menu: (provided: any) => ({
            ...provided,
            width: "150px",
            left: "-150%",
            top: "50px",
            border: "1px solid var(--icon-blue)",
            '::before': {
                content: '""',
                position: "absolute",
                top: "-15px",
                right: "16%",
                width: "0",
                height: "0",
                borderLeft: "10px solid transparent",
                borderRight: "10px solid transparent",
                borderBottom: "15px solid var(--icon-blue)",
            },
        }),
    };

    function getLogo(id: number) {
        switch (id) {
            case 3:
                return EnglishFlag;
            case 5:
                return FrenchFlag;
            case 2:
                if (RouteUtils.getContext() === 'be' || RouteUtils.getContext() === 'ci') {
                    return DutchBEFlag;
                } else {
                    return DutchFlag;
                }
            default:
                return EnglishFlag;
        }
    }

    function getLanguageCode(id: number) {
        switch (id) {
            case 3:
                return 'En';
            case 5:
                return 'Fr';
            case 2:
                return 'Nl';
            default:
                return 'En';
        }
    }

    const CustomSingleValue = (props: SingleValueProps<LanguageView>) => (
        <components.SingleValue {...props}>
            <div className={"d-flex justify-content-center align-items-center text-center"}>
                <img src={getLogo(props.data.id)} alt={t(`${props.data.code}`)}
                     style={{width: '20px', height: "15px"}}/>
                <span style={{marginLeft: '2px'}}>{getLanguageCode(props.data.id)}</span>
            </div>
        </components.SingleValue>
    );

    if (isLoading) {
        return null;
    }

    return (
        <Select
            options={languages}
            value={selectedLanguage}
            onChange={onLanguageChange}
            components={{Option: CustomOption, SingleValue: CustomSingleValue}}
            styles={customStyles}
            isSearchable={false}
        />
    );
};

export default LanguageSwitcher;
